<template>
  <Head>
<!--    <Script type="text/javascript" src="https://rawgit.com/JulianLaval/canvas-particle-network/master/particle-network.min.js" defer="true"></Script>-->
<!--    <Script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js" defer="true"></Script>-->
  </Head>
  <div
    class="home-page bg-idle-white dark:bg-gradient-to-b from-[#0079B8] to-[#004482]"
  >
    <div id="particle-canvas" class="min-h-screen z-10"></div>

    <div class="absolute top-0 w-full">
      <nav
        class="sticky top-0 z-50 border-b border-primary dark:border-gray-100 bg-idle-white dark:bg-gradient-to-r from-[#0079B8] via-[#0079B8] to-[#004482]"
      >
        <div
          class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4"
        >
          <div class="flex items-center">
            <NuxtLink
              to="/"
              class="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img
                src="/images/logos/toolstation-logo-halo.svg"
                width="164"
                alt="Flowbite Logo"
              />
            </NuxtLink>
          </div>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden text-gray-400"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div class="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul
              class="font-medium flex flex-col items-center p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 dark:border-gray-700"
            >
              <li>
                <NuxtLink
                  to="/ui/"
                  class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  exact-active-class="!text-natural-grey dark:!text-natural-dark-grey"
                  active-class="!text-natural-grey dark:!text-natural-dark-grey"
                >
                  Home
                </NuxtLink>
              </li>

              <li>
                <NuxtLink
                  to="/ui/guides/introduction"
                  exact-active-class="!text-primary dark:!text-primary"
                  active-class="!text-primary dark:!text-primary"
                  class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Guide
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  to="/ui/atoms"
                  exact-active-class="!text-primary dark:!text-primary"
                  active-class="!text-primary dark:!text-primary"
                  class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Atoms
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  to="/ui/molecules"
                  class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  exact-active-class="!text-primary dark:!text-primary"
                  active-class="!text-primary dark:!text-primary"
                >
                  Molecules
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  to="/ui/organisms"
                  class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  exact-active-class="!text-primary dark:!text-primary"
                  active-class="!text-primary dark:!text-primary"
                >
                  Organisms
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  to="/ui/templates"
                  class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  exact-active-class="!text-primary dark:!text-primary"
                  active-class="!text-primary dark:!text-primary"
                >
                  Templates
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  to="/ui/pages"
                  class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  exact-active-class="!text-primary dark:!text-primary"
                  active-class="!text-primary dark:!text-primary"
                >
                  Pages
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div class="content-main relative z-10">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
// import { useHead } from '@vueuse/head';

// useHead({
//   script: [
//     {
//       src: "https://rawgit.com/JulianLaval/canvas-particle-network/master/particle-network.min.js",
//       type: "text/javascript",
//       defer: "true"
//     },
//     {
//       src: "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js",
//       defer: "true"
//     },
//   ]
// })


onMounted(() => {
  var canvasDiv = document.getElementById("particle-canvas");
  var options = {
    particleColor: "#6090d0",

    interactive: true,
    speed: "low",
    density: "low",
  };
  var particleCanvas = new ParticleNetwork(canvasDiv, options);
});


</script>
